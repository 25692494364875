<template>
  <v-container
    class="pms-employees-list-container"
    fluid>
    <v-row justify-md="space-between">
      <v-col
        cols="12"
        md="4">
        <h2>Employees List</h2>
      </v-col>

      <v-col
        cols="12"
        md="5">
        <div class="d-flex flex-row justify-end align-center">
          <v-select
            v-model="query.role"
            class="mr-2"
            hide-details
            label="Role"
            outlined
            dense
            color="secondary"
            :items="roles"
            item-text="text"
            item-value="value">
          </v-select>
          <v-select
            v-model="query.status"
            hide-details
            label="Status"
            outlined
            dense
            color="secondary"
            :items="status"
            item-text="text"
            item-value="value">
          </v-select>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="3">
        <div class="d-flex flex-row justify-end align-center">
          <search-box
            v-model="query.search"
            @on-search="getEmployees(true)" />
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="employees"
          :item-per-page="query.limit"
          :loading="loading"
          hide-default-footer>
          <template #[`item.name`]="{ item }">
            {{ `${item.firstName} ${item.lastName}` }}
          </template>
          <template #[`item.role`]="{ item }">
            {{ mapRole(item.role) }}
          </template>
          <template #[`item.status`]="{ item }">
            {{ item.status }}
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn
              color="secondary"
              small
              icon
              :to="`/pms/employee-detail/${item.userId}`">
              <v-icon small>
                mdi-magnify
              </v-icon>
            </v-btn>
            <v-btn
              color="error"
              small
              icon
              @click="confirmRemoveEmployee(item)">
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="query.page"
          :length="pageCount"
          :disabled="loading"
          total-visible="7"
          color="secondary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import EmployeeProvider from '@/resources/EmployeeProvider'
import EmployeeRoles from '@/assets/json/EmployeeRoles.json'

const EmployeeService = new EmployeeProvider()

export default {
  components: {
    SearchBox
  },
  data () {
    return {
      headers: [
        {
          text: 'รหัส',
          value: 'userId'
        },
        {
          text: 'ชื่อ - นามสกุล',
          value: 'name'
        },
        {
          text: 'ตำแหน่ง',
          value: 'role',
          cellClass: 'text-capitalize'
        },
        {
          text: 'สถานะ',
          value: 'status',
          cellClass: 'text-capitalize'
        },
        {
          text: 'ตัวเลือก',
          value: 'action',
          sortable: false
        }
      ],
      employees: [],
      query: {
        page: 1,
        limit: 10,
        role: null,
        status: null,
        search: ''
      },
      roles: [
        {
          text: 'All',
          value: null
        },
        {
          text: 'Sales Staff',
          value: EmployeeRoles.SALES_STAFF
        },
        {
          text: 'Assist Store Manager',
          value: EmployeeRoles.ASSIST_STORE_MANAGER
        },
        {
          text: 'Store Manager',
          value: EmployeeRoles.STORE_MANAGER
        },
        {
          text: 'Area Manager',
          value: EmployeeRoles.AREA_MANAGER
        }
      ],
      status: [
        {
          text: 'All',
          value: null
        },
        {
          text: 'Active',
          value: 'active'
        },
        {
          text: 'Resign',
          value: 'resign'
        }
      ],
      pageCount: 0,
      loading: false
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getEmployees()
      },
      deep: true
    }
  },
  mounted () {
    this.getEmployees(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal'
    }),
    async getEmployees (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.employees = []
        }

        const { data: employeeResponse } = await EmployeeService.getEmployees({
          page: this.query.page,
          limit: this.query.limit,
          role: this.query.role,
          status: this.query.status,
          search: this.query.search
        })

        this.employees = employeeResponse.results
        this.pageCount = employeeResponse.pages
      } catch (error) {
        console.error('getEmployees', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    mapRole (role) {
      switch (role) {
        case EmployeeRoles.SALES_STAFF: return 'Sales Staff'
        case EmployeeRoles.ASSIST_STORE_MANAGER: return 'Assist Store Manager'
        case EmployeeRoles.STORE_MANAGER: return 'Store Manager'
        case EmployeeRoles.AREA_MANAGER: return 'Area Manager'
        default: return role
      }
    },
    confirmRemoveEmployee (emp) {
      this.setModal({
        value: true,
        title: 'Remove Employee',
        message: `Do you want to remove ${emp.firstName}?`,
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.removeEmployee(emp.id)
      })
    },
    async removeEmployee (id) {
      try {
        this.loading = true

        await EmployeeService.deleteEmployee(id)

        this.getEmployees(true)
      } catch (error) {
        console.error('removeEmployee', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.pms-employees-list-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 12px;
}
.v-text-field {
  padding-top: 0;
  margin-top: 0;
  max-width: 250px;
  width: 80%;
  float: right;
}
.tableHeader > th {
  font-size: 0.85rem !important;
}
.overlay-progress {
  margin-top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
</style>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
