var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pms-employees-list-container",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify-md":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('h2',[_vm._v("Employees List")])]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"d-flex flex-row justify-end align-center"},[_c('v-select',{staticClass:"mr-2",attrs:{"hide-details":"","label":"Role","outlined":"","dense":"","color":"secondary","items":_vm.roles,"item-text":"text","item-value":"value"},model:{value:(_vm.query.role),callback:function ($$v) {_vm.$set(_vm.query, "role", $$v)},expression:"query.role"}}),_c('v-select',{attrs:{"hide-details":"","label":"Status","outlined":"","dense":"","color":"secondary","items":_vm.status,"item-text":"text","item-value":"value"},model:{value:(_vm.query.status),callback:function ($$v) {_vm.$set(_vm.query, "status", $$v)},expression:"query.status"}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex flex-row justify-end align-center"},[_c('search-box',{on:{"on-search":function($event){return _vm.getEmployees(true)}},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.employees,"item-per-page":_vm.query.limit,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.firstName) + " " + (item.lastName)))+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mapRole(item.role))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","small":"","icon":"","to":("/pms/employee-detail/" + (item.userId))}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-magnify ")])],1),_c('v-btn',{attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.confirmRemoveEmployee(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"disabled":_vm.loading,"total-visible":"7","color":"secondary"},model:{value:(_vm.query.page),callback:function ($$v) {_vm.$set(_vm.query, "page", $$v)},expression:"query.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }